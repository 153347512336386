@use "basically-basic/mixins/lists";

/* ==========================================================================
   Contact lists
   ========================================================================== */

.contact-list {
  @include lists.list-unstyled;

  .icon {
    margin-right: 0.25em;
  }
}
