@use "basically-basic/variables";
@use "basically-basic/vendor/breakpoint/breakpoint";
// @use "basically-basic/mixins";
 @use "basically-basic/mixins/lists"; // Import lists mixin first

/* ==========================================================================
   Layout
   ========================================================================== */

.canvas {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  transform: translateX(0);
  transition: variables.$global-transition;
  background-color: variables.$background-color;
  z-index: 100;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding-left: 2rem;
}

.intro,
main {
  > .inner {
    padding: 0 0.5rem;

    @include breakpoint.breakpoint(variables.$small) {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    @include breakpoint.breakpoint(variables.$medium) {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    @include breakpoint.breakpoint(variables.$large) {
      padding-right: 3rem;
      padding-left: 3rem;
    }

    @include breakpoint.breakpoint(variables.$xlarge) {
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }
}

main {
  section {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}

.inner {
  max-width: variables.$xlarge;
}

/*
   Layout specific adjustments
   ========================================================================== */

.layout--post,
.layout--page {
  .entry-content {
    @include breakpoint.breakpoint(variables.$large) {
      width: span(14 of 16);
    }
  }
}

.layout--about {
  .entry-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @include breakpoint.breakpoint(variables.$medium) {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
  }

  .entry-content {
    @include breakpoint.breakpoint(variables.$medium) {
      -ms-flex: 1;
      flex: 1;
    }
  }

  .entry-sidebar {
    order: -1;
    -ms-flex-order: -1;

    @include breakpoint.breakpoint(variables.$medium) {
      order: initial;
      -ms-flex-order: initial;
    }

    .author-picture {
      margin: 0 0 2rem;
      border-radius: 2 * variables.$border-radius;

      @include breakpoint.breakpoint(variables.$medium) {
        margin: 0 0 0 2rem;
      }
    }
  }
}

.layout--cv {
  .entries {
    .entry-header {
      @include breakpoint.breakpoint(variables.$medium) {
        width: span(5 of 16);
        padding-right: gutter(1 of 16);

        /* remove space after last child element */
        > *:last-child {
          margin-bottom: 0;
        }
      }
    }

    .entry-content {
      @include breakpoint.breakpoint(variables.$medium) {
        float: right;
        width: span(11 of 16);
      }
    }
  }

  .entry {
    a {
      color: variables.$accent-color;
    }

    address {
      font-style: normal;
    }

    .title,
    .position,
    .institution,
    .language {
      margin-bottom: 0;
    }

    .date {
      margin-bottom: 1rem;
      color: tint(variables.$text-color, 40%);
      font-family: variables.$monospace-font-family;
      font-size: 80%;
    }

    .courses-title,
    .awarder,
    .publisher,
    .level {
      margin: 1rem 0 0;
    }

    .courses,
    .keywords {
      @include lists.list-unstyled;
    }
  }

  .taxonomy {
    + .taxonomy {
      margin-top: 0.5rem;
    }

    .title {
      display: inline-block;
      margin-right: 1rem;
    }

    .keywords {
      display: inline-block;
    }
  }
}
