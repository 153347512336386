@use "basically-basic/variables";

/* ==========================================================================
   Accessibility Modules
   ========================================================================== */

/* Text meant only for screen readers */
.screen-reader-text,
.screen-reader-text span,
.screen-reader-shortcut {
  position: absolute !important;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  overflow: hidden;

  &:focus {
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    padding: 0.75em 1em;
    clip: auto !important;
    border-radius: 0.125em;
    background-color: variables.$base-color;
    color: #fff;
    font-weight: bold;
    line-height: normal;
    text-decoration: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    z-index: 100000;
  }
}

.screen-reader-text:focus,
.screen-reader-shortcut:focus {
  width: auto;
  height: auto;
  clip: auto !important;
  text-decoration: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  z-index: 100000;
}
