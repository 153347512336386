@use "basically-basic/functions/fluid-type";
@use "basically-basic/mixins/clearfix";
@use "basically-basic/mixins/lists";
@use "basically-basic/variables";
@use "basically-basic/vendor/breakpoint/breakpoint";

/* ==========================================================================
   Entries (Posts, collection documents, etc.)
   ========================================================================== */

.entry {
  @include clearfix.clearfix();
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  &:not(:last-child) {
    border-bottom: 1px solid variables.$border-color;
  }

  a {
    color: inherit;
  }
}

.entry-title {
  margin-bottom: 0.5rem;
  color: tint(variables.$text-color, 25%);
  line-height: inherit;

  a {
    text-decoration: none;
  }
  @include fluid-type.fluid-type(variables.$min-vw, variables.$max-vw, 18px, 24px);
}

.entry-meta {
  margin-bottom: 1rem;

  a {
    color: inherit;
  }

  .byline-item {
    &:not(:first-child) {
      margin-left: 1.5rem;
    }
  }

  .icon {
    margin-right: 0.5em;
    fill: variables.$accent-color;
  }
  @include fluid-type.fluid-type(variables.$min-vw, variables.$max-vw, 12px, 16px);
}

/*
   Entry list
   ========================================================================== */

.entries {
  .entry-title {
    word-wrap: break-word; /* break long words that could overflow */

    a:hover {
      color: variables.$accent-color;
    }
  }

  .entry-excerpt {
    @include breakpoint.breakpoint(variables.$medium) {
      width: span(11 of 16);
    }

    /* normalize font sizes */
    > * {
      font-size: 1em;
    }

    /* remove space after last child element */
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .entry-content {
    @include breakpoint.breakpoint(variables.$medium) {
      width: span(11 of 16);
    }

    /* remove space after last child element */
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .entry-meta {
    @include breakpoint.breakpoint(variables.$medium) {
      float: right;
      width: span(5 of 16 last);
      padding-left: gutter(1 of 16);
    }

    ul {
      @include lists.list-unstyled();

      @include breakpoint.breakpoint(max-width variables.$medium) {
        li {
          display: inline-block;
          margin-right: 1.5rem;
        }
      }

      @include breakpoint.breakpoint(variables.$medium) {
        li {
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  > .summary {
    > p:first-child {
      font-size: 1.125em;
      line-height: 1.625;
    }
  }
}

.entry-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: tint(variables.$text-color, 25%);
  }
}

/* Footnotes */
.footnotes {
  margin: 2rem 0;
  padding-top: 1rem;
  border-top: 1px solid variables.$border-color;
  font-size: 80%;
}

/* Read more link */
.more-link {
  font-size: 75%;
  font-weight: bold;
  text-decoration: none;
}
