@use "basically-basic/functions/fluid-type";
@use "basically-basic/variables";

/* ==========================================================================
   Tables
   ========================================================================== */

table {
  width: 100%;
  border-collapse: collapse;

  word-break: normal;
  word-break: keep-all;
  @include fluid-type.fluid-type(variables.$min-vw, variables.$max-vw, 12px, 18px);
}

th,
td {
  border-bottom: 1px solid variables.$border-color;
}

th {
  padding: 0.5em;
  font-weight: bold;
  text-align: left;
}

td {
  padding: 0.5em;
}

tr,
td,
th {
  vertical-align: middle;
}
