@use "basically-basic/variables";

/* ==========================================================================
   Syntax highlighting
   ========================================================================== */

div.highlighter-rouge,
figure.highlight {
  position: relative;
  margin-bottom: 1em;
  background: variables.$base00;
  color: variables.$base05;
  font-family: variables.$monospace-font-family;
  line-height: 1.8;

  > pre,
  pre.highlight {
    margin: 0;
    padding: 1em;
  }
}

.highlight table {
  margin-bottom: 0;
  font-size: 1em;
  border: 0;

  td {
    padding: 0;
    width: calc(100% - 1em);
    border: 0;

    /* line numbers*/
    &.gutter {
      padding-right: 1em;
      width: 1em;
      color: variables.$base04;
      border-right: 1px solid variables.$base04;
      text-align: right;
    }

    /* code */
    &.code {
      padding-left: 1em;
    }
  }

  pre {
    margin: 0;
  }
}

.highlight pre {
  width: 100%;
}

.highlight .hll {
  background-color: variables.$base06;
}
.highlight {
  .c {
    /* Comment */
    color: variables.$base04;
  }
  .err {
    /* Error */
    color: variables.$base08;
  }
  .k {
    /* Keyword */
    color: variables.$base0e;
  }
  .l {
    /* Literal */
    color: variables.$base09;
  }
  .n {
    /* Name */
    color: variables.$base05;
  }
  .o {
    /* Operator */
    color: variables.$base0c;
  }
  .p {
    /* Punctuation */
    color: variables.$base05;
  }
  .cm {
    /* Comment.Multiline */
    color: variables.$base04;
  }
  .cp {
    /* Comment.Preproc */
    color: variables.$base04;
  }
  .c1 {
    /* Comment.Single */
    color: variables.$base04;
  }
  .cs {
    /* Comment.Special */
    color: variables.$base04;
  }
  .gd {
    /* Generic.Deleted */
    color: variables.$base08;
  }
  .ge {
    /* Generic.Emph */
    font-style: italic;
  }
  .gh {
    /* Generic.Heading */
    color: variables.$base05;
    font-weight: bold;
  }
  .gi {
    /* Generic.Inserted */
    color: variables.$base0b;
  }
  .gp {
    /* Generic.Prompt */
    color: variables.$base04;
    font-weight: bold;
  }
  .gs {
    /* Generic.Strong */
    font-weight: bold;
  }
  .gu {
    /* Generic.Subheading */
    color: variables.$base0c;
    font-weight: bold;
  }
  .kc {
    /* Keyword.Constant */
    color: variables.$base0e;
  }
  .kd {
    /* Keywvariables.$baseeclaration */
    color: variables.$base0e;
  }
  .kn {
    /* Keywvariables.$baseamespace */
    color: variables.$base0c;
  }
  .kp {
    /* Keywvariables.$baseseudo */
    color: variables.$base0e;
  }
  .kr {
    /* Keywvariables.$baseeserved */
    color: variables.$base0e;
  }
  .kt {
    /* Keywvariables.$baseype */
    color: variables.$base0a;
  }
  .ld {
    /* Litevariables.$baseate */
    color: variables.$base0b;
  }
  .m {
    /* Litevariables.$baseumber */
    color: variables.$base09;
  }
  .s {
    /* Litevariables.$basetring */
    color: variables.$base0b;
  }
  .na {
    /* Namevariables.$baseibute */
    color: variables.$base0d;
  }
  .nb {
    /* Namevariables.$basetin */
    color: variables.$base05;
  }
  .nc {
    /* Namevariables.$bases */
    color: variables.$base0a;
  }
  .no {
    /* Namevariables.$basetant */
    color: variables.$base08;
  }
  .nd {
    /* Namevariables.$baserator */
    color: variables.$base0c;
  }
  .ni {
    /* Namevariables.$basety */
    color: variables.$base05;
  }
  .ne {
    /* Namevariables.$baseption */
    color: variables.$base08;
  }
  .nf {
    /* Namevariables.$basetion */
    color: variables.$base0d;
  }
  .nl {
    /* Namevariables.$basel */
    color: variables.$base05;
  }
  .nn {
    /* Namevariables.$basespace */
    color: variables.$base0a;
  }
  .nx {
    /* Namevariables.$baser */
    color: variables.$base0d;
  }
  .py {
    /* Namevariables.$baseerty */
    color: variables.$base05;
  }
  .nt {
    /* Namevariables.$base*/
    color: variables.$base0c;
  }
  .nv {
    /* Namevariables.$baseable */
    color: variables.$base08;
  }
  .ow {
    /* Opervariables.$baseWord */
    color: variables.$base0c;
  }
  .w {
    /* Textvariables.$baseespace */
    color: variables.$base05;
  }
  .mf {
    /* Litevariables.$baseumber.Float */
    color: variables.$base09;
  }
  .mh {
    /* Litevariables.$baseumber.Hex */
    color: variables.$base09;
  }
  .mi {
    /* Litevariables.$baseumber.Integer */
    color: variables.$base09;
  }
  .mo {
    /* Litevariables.$baseumber.Oct */
    color: variables.$base09;
  }
  .sb {
    /* Litevariables.$basetring.Backtick */
    color: variables.$base0b;
  }
  .sc {
    /* Litevariables.$basetring.Char */
    color: variables.$base05;
  }
  .sd {
    /* Litevariables.$basetring.Doc */
    color: variables.$base04;
  }
  .s2 {
    /* Litevariables.$basetring.Double */
    color: variables.$base0b;
  }
  .se {
    /* Litevariables.$basetring.Escape */
    color: variables.$base09;
  }
  .sh {
    /* Litevariables.$basetring.Heredoc */
    color: variables.$base0b;
  }
  .si {
    /* Litevariables.$basetring.Interpol */
    color: variables.$base09;
  }
  .sx {
    /* Litevariables.$basetring.Other */
    color: variables.$base0b;
  }
  .sr {
    /* Litevariables.$basetring.Regex */
    color: variables.$base0b;
  }
  .s1 {
    /* Litevariables.$basetring.Single */
    color: variables.$base0b;
  }
  .ss {
    /* Litevariables.$basetring.Symbol */
    color: variables.$base0b;
  }
  .bp {
    /* Namevariables.$basetin.Pseudo */
    color: variables.$base05;
  }
  .vc {
    /* Namevariables.$baseable.Class */
    color: variables.$base08;
  }
  .vg {
    /* Namevariables.$baseable.Global */
    color: variables.$base08;
  }
  .vi {
    /* Namevariables.$baseable.Instance */
    color: variables.$base08;
  }
  .il {
    /* Litevariables.$baseumber.Integer.Long */
    color: variables.$base09;
  }
}

.gist {
  th, td {
    border-bottom: 0;
  }
}
