@use "basically-basic/functions/fluid-type";
@use "basically-basic/mixins/lists";
@use "basically-basic/variables";

/* ==========================================================================
   Page intro
   ========================================================================== */

.intro {
  margin: 1rem 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.intro-image {
  position: relative;
  margin-top: -3rem; /* compensate for intro top margin */
  margin-bottom: 3rem;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(variables.$accent-color, 0.25);
    content: '';
  }

  img {
    width: 100%;
  }
}

.intro-text {

  /* remove space after last child element */
  > *:last-child {
    margin-bottom: 0;
  }
}

.intro-title {
  margin-bottom: 1rem;
  color: tint(variables.$text-color, 25%);
  line-height: 1;
  @include fluid-type.fluid-type(variables.$min-vw, variables.$max-vw, 26px, 80px);
}

.intro-subtitle {
  font-style: italic;
  @include fluid-type.fluid-type(variables.$min-vw, variables.$max-vw, 18px, 28px);
}

.intro-actions {
  @include lists.list-unstyled;

  li {
    display: inline-block;
  }
}

.intro-more {

  > p:first-child {
    font-size: 1.125em;
    line-height: 1.625;
  }
}
