@use "basically-basic/variables";

/* ==========================================================================
   Footer
   ========================================================================== */

.site-footer {
  margin-top: 4rem;
  color: tint(variables.$text-color, 40%);
  font-size: 80%;
}

.copyright {
  font-family: variables.$monospace-font-family;
}
