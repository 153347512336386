@use "basically-basic/mixins/lists";
@use "basically-basic/mixins/clearfix";
@use "basically-basic/variables";

/* ==========================================================================
   Navigation
   ========================================================================== */

.menu {
  @include lists.list-unstyled;

  a {
    color: inherit;
    text-decoration: none;
  }
}

/*
   Skip links
   ========================================================================== */

.skip-links {
  position: absolute;
  width: 100%;

  ul {
    @include lists.list-unstyled;
  }
}

/*
   Paginator
   ========================================================================== */

.pager {
  @include clearfix.clearfix();
  margin-bottom: 2rem;

  ul {
    @include lists.list-unstyled;
    display: -ms-flexbox;
    display: flex;

    > li {
      -ms-flex: 1;
      flex: 1;
      justify-content: space-between;
      -ms-flex-pack: justify;
    }

    li + li {
      margin-left: 0.125em;
    }
  }

  a {
    display: block;
    padding: 1em;
    border-radius: variables.$border-radius;
    background-color: variables.$accent-color;
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    &:hover {
      background-color: tint(variables.$accent-color, 20%);
    }
  }
}
